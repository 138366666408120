<template>
  <div id="app">
    <div class="container">
      <div class="content">
        <div class="qq" @click="goto()"></div>
        <div class="fly" @click="goto()"></div>
        <div class="tg" @click="goto()"></div>
        <div class="tgBtn" @click="copy('tg')"></div>
        <div class="qqBtn" @click="copy('qq')"></div>
        <div class="flyBtn" @click="copy('fly')"></div>
        <div class="skyBtn" @click="copy('sky')"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      platformUrl: '',
      affID: 3199,
    }
  },
  created () {
    this.getEnv();
  },
  computed: {
    isMobile() {
      const regexpText = 'iPhone|iPod|iPad|\biOS-universal(?:.+)Mac\b|\bAndroid(?:.+)Mobile\b|Android|(?:SD4930UR|\bSilk(?:.+)Mobile\b)|Silk|Windows Phone|\bWindows(?:.+)ARM\b|BlackBerry|BB10|Opera Mini|\b(CriOS|Chrome)(?:.+)Mobile|Mobile(?:.+)Firefox\b';
      const regexp = new RegExp(regexpText, 'i');
      return regexp.test(navigator.userAgent);
    },
  },
  methods: {
    copy(param) {
      let value;
      const el = document.createElement('textarea');
      el.readOnly = true;
      switch (param) {
        case 'qq':
          value = 'xinli'
          el.value = value;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          el.blur();
          document.body.removeChild(el);
          window.alert('复制成功');
          break;
        case 'fly':
          value = '342342'
          el.value = value;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          el.blur();
          document.body.removeChild(el);
          window.alert('复制成功');
          break;
          case 'sky':
          value = '+639776648510'
          el.value = value;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          el.blur();
          document.body.removeChild(el);
          window.alert('复制成功');
          break;
        case 'tg':
          value = '@xinli18'
          el.value = value;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          el.blur();
          document.body.removeChild(el);
          window.alert('复制成功');
          break;
        default:
          break;
      }
    },
    async getEnv() {
      const VUE_APP_ENV_JSON = 'https://cdn.xinli18app.com/env.json';
      const res = await fetch(VUE_APP_ENV_JSON).then((res) => res.json());
      this.platformUrl = this.isMobile ? res.mobileWeb : res.platformUrl;
    },
    goto() {
      window.location = `https://www.xl5188.com/`;
    },
  }
}
</script>

<style lang="scss">
@import '../src/style/_variables';

* {
  margin: 0;
  padding: 0;
}

body, html, #app {
  position: relative;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
}

.container {
  position: absolute;
  width: 1920px;
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  overflow-x: hidden;
}
// PC
.content {
  margin: 0 auto;
  position: relative;
  width: 1920px;
  height: 1080px;
  background: url('../src/assets/PC.jpg') center center / contain  no-repeat;
}
.qqBtn, .flyBtn, .tgBtn, .skyBtn {
  position: absolute;
  z-index: 1;
  height: 68px;
  bottom: 113px;
}

.qqBtn{
    width: 236px;
    left:682px;
    background: url('../src/assets/QQPC.png') center center / contain no-repeat;
}

.flyBtn{
    width: 236px;
    left:928px;
    background: url('../src/assets/微信PC.png') center center / contain no-repeat;
}

.tgBtn{
    width: 299px;
    left:379px;
    background: url('../src/assets/TelegramPC.png') center center / contain no-repeat;
}

.skyBtn{
    width: 370px;
    left:1174px;
    background: url('../src/assets/skyPC.png') center center / contain no-repeat;
}

.qq, .fly, .tg {
  position: absolute;
  z-index: 1;
  width: 450px;
  height: 70px;
  left: 375px;
}

.qq {
  top: 590px;
  background: url('../src/assets/btn1.png') center center / contain no-repeat;
}

.fly {
  top: 670px;
  background: url('../src/assets/btn2.png') center center / contain no-repeat;
}

.tg {
  top: 750px;
  background: url('../src/assets/btn3.png') center center / contain no-repeat;
}

// 1200 ~ 768
@media (max-width: #{$pcrwd-breakpoint + 'px'}) {
  .content {
    width: vw(1920px);
    height: vw(1080px);
  }
  .qqBtn, .flyBtn, .tgBtn, .skyBtn {
    height: vw(68px);
    bottom: vw(113px);
  }

  .qqBtn{
    width: vw(236px);
    left: vw(682px);
  }

  .flyBtn{
    width: vw(236px);
    left: vw(928px);
  }

  .tgBtn{
    width: vw(299px);
    left: vw(379px);
  }

  .skyBtn{
    width: vw(370px);
    left: vw(1174px);
  }

  .qq, .fly, .tg {
    width: vw(450px);
    height: vw(70px);
    left: vw(375px);
  }

  .qq {
    top: vw(590px);
  }

  .fly {
    top: vw(670px);
  }

  .tg {
    top: vw(750px);
  }

}
// H5
@media (max-width: #{$mblrwd-breakpoint + 'px'}) {

  .content {
    width: vwm(768px);
    height: vwm(2300px);
    background: url('../src/assets/H5.jpg') center center / contain no-repeat;
  }

  .qqBtn, .flyBtn, .tgBtn, .skyBtn {
    width: vwm(663px);
    height: vwm(131px);
    left: vwm(52px);
  }

  .qq, .fly, .tg {
    width: vwm(666px);
    height: vwm(108px);
    left: vwm(50px);
  }

  .qq {
    top: vwm(1180px);
    background: url('../src/assets/btn1.png') center center / contain no-repeat;
  }

  .fly {
    top: vwm(1300px);
    background: url('../src/assets/btn2.png') center center / contain no-repeat;
  }

  .tg {
    top: vwm(1420px);
    background: url('../src/assets/btn3.png') center center / contain no-repeat;
  }

  .qqBtn{
    top: vwm(1834px);
    background: url('../src/assets/QQ.png') center center / contain no-repeat;
  }

  .flyBtn{
    top: vwm(1977px);
    background: url('../src/assets/微信.png') center center / contain no-repeat;
  }

  .tgBtn{
    top: vwm(1693px);
    background: url('../src/assets/Telegram.png') center center / contain no-repeat;
  }
  .skyBtn{
    top: vwm(2112px);
    background: url('../src/assets/skype.png') center center / contain no-repeat;
  }
}
</style>
